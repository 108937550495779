/* App.css */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f8f8f8; /* Light gray background */
}

.App {
  text-align: center;
}

header {
  background-color: #1a1a1a; /* Dark gray header */
  color: white;
  padding: 1rem;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  display: inline;
  margin-right: 10px;
}

nav a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #ffcc00; /* Yellow on hover */
}

.hero {
  background-color: #e6e6e6; /* Light gray hero section */
  padding: 2rem;
}

.about,
.issues,
.contact {
  background-color: white;
  padding: 2rem;
  margin: 1rem 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.issues ul {
  list-style-type: none;
  padding: 0;
}

.issues li {
  margin-bottom: 8px;
}

footer {
  background-color: #1a1a1a; /* Dark gray footer */
  color: white;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
}

footer p {
  margin: 0;
}

/* Patriotic Colors */
.hero h2,
.about h2,
.issues h2,
.contact h2,
footer h2 {
  color: #003366; /* Dark blue */
}

.hero p,
.about p,
.contact p,
.footer p {
  color: #333; /* Dark gray */
}

/* Styling for Links */
a {
  color: #003366; /* Dark blue */
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #ffcc00; /* Yellow on hover */
}

.flag-container {
  display: flex;
  align-items: center;
}

.american-flag {
  width: 100px; /* Set a specific width */
  height: auto; /* Allow the height to adjust proportionally */
  margin-right: 10px;
  margin-bottom: 10px;
}